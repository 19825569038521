import pick from 'lodash/pick'
import isEqual from 'lodash/isEqual'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, prepareAutoBatched } from '@reduxjs/toolkit'
import type { MulticallBalances } from '../services/ethereum/multicall'
import type { CrossChainBalances } from '../services/crossChainSwapServiceNew/getCrossChainBalances'
import type { DvfPermissions } from '../services/apiService'
import { isWalletConnectedAction, isWalletDisconnectAction } from '../actions/userActions/connectWalletActions'
import { isAddressChangeAction } from '../actions/userActions/addressChangeActions'

import { getDefaultBridgeRoute } from '../pages/Bridge/helpers/getDefaultBridgeRoute'
import type { DefaultRouteType } from '../pages/Bridge/types/BridgeWidget.types'
import type { PendingDeposit } from './types/PendingDepositState'
import type { PortalConfigFromBackend, PortalReducerState, RequiredChain, ThemeName } from './types/PortalReducerState'
import type { ExchangeBalanceState } from './types/ExchangeBalanceState'
import type { WithdrawalState } from './types/WithdrawalState'
import type { DepositState } from './types/DepositState'

const defaultTheme: ThemeName = 'dune'

export const getInitialState = (): PortalReducerState => ({
  // User data
  permissions: null,
  signatureAddress: null,

  // Meta data
  selectedTheme: defaultTheme,
  requiredChain: null,
  providerIsLate: 0,

  // API / Blockchain Data
  blockNumberPerChain: {},
  withdrawals: [],
  deposits: [],
  pendingDepositData: [],
  blockchainBalance: {},
  failedChains: {},
  hasBalanceForTransaction: {},
  exchangeBalance: {},
  exchangeBalanceFetched: false,
  settleSpread: 0,
  tokenRegistry: {},
  nonL2TokenRegistry: {},
  exchangeSymbols: [],
  newMarkets: [],
  latestTx: '',
  batchTime: {
    estimatedTime: 0,
    averageTime: 0,
  },
  starkExVersion: null,
  ammPools: {},
  crossChainBalanceQuantized: [],
  openOrders: [],
  minDepositUSDT: 0,
  bridgeConfigPerChain: {},
  transfers: {},
  crossChainSwaps: {},
  highlightedSymbol: '',
  dlmMarkets: [],
  yieldMarkets: {},
  defaultRoute: getDefaultBridgeRoute(),
})

export const portalSlice = createSlice({
  name: 'portal',
  initialState: getInitialState(),
  extraReducers: (builder) => {
    const cleanState = {
      blockchainBalance: {},
      exchangeBalance: {},
      withdrawals: [],
      deposits: [],
      pendingDepositData: [],
      transfers: {},
      crossChainSwaps: {},
      crossChainBalanceQuantized: [],
      latestTx: '',
      exchangeBalanceFetched: false,
    }
    const reset = (state: PortalReducerState) => {
      return {
        ...state,
        ...cleanState,
      }
    }
    builder.addMatcher(isWalletConnectedAction, reset)
    builder.addMatcher(isAddressChangeAction, reset)
    builder.addMatcher(isWalletDisconnectAction, reset)
  },
  reducers: {
    updateBlockchainBalance: {
      reducer: (
        state,
        {
          payload: { blockchainBalance, failedChains, hasBalanceForTransaction },
        }: PayloadAction<{
          blockchainBalance: MulticallBalances
          failedChains: { [chain: string]: boolean }
          hasBalanceForTransaction: Record<string, boolean>
        }>,
      ) => {
        return {
          ...state,
          blockchainBalance: {
            ...state.blockchainBalance,
            ...blockchainBalance,
          },
          hasBalanceForTransaction: {
            ...state.hasBalanceForTransaction,
            ...hasBalanceForTransaction,
          },
          failedChains: {
            ...state.failedChains,
            ...failedChains,
          },
        }
      },
      prepare: prepareAutoBatched(),
    },
    updateExchangeBalance: (
      state,
      { payload: { exchangeBalance } }: PayloadAction<{ exchangeBalance: ExchangeBalanceState }>,
    ) => {
      return {
        ...state,
        exchangeBalance,
        exchangeBalanceFetched: true,
      }
    },
    updateCrossChainBalanceQuantized: (
      state,
      { payload: { crossChainBalanceQuantized } }: PayloadAction<{ crossChainBalanceQuantized: CrossChainBalances }>,
    ) => {
      return {
        ...state,
        crossChainBalanceQuantized,
      }
    },
    setUserSignatureAddress: (
      state,
      { payload: { signatureAddress } }: PayloadAction<{ signatureAddress: string }>,
    ) => {
      return {
        ...state,
        signatureAddress,
      }
    },
    setUserPermissions: (state, { payload: { permissions } }: PayloadAction<{ permissions: DvfPermissions }>) => {
      return {
        ...state,
        permissions,
      }
    },
    setPortalReducerProviderIsLate: (
      state,
      { payload: { providerIsLate } }: PayloadAction<{ providerIsLate: number }>,
    ) => {
      return {
        ...state,
        providerIsLate,
      }
    },
    toggleTheme: (state, { payload: { theme } }: PayloadAction<{ theme: ThemeName }>) => {
      localStorage.setItem('theme', theme)

      return {
        ...state,
        selectedTheme: theme,
      }
    },
    setConfig: (state, action: PayloadAction<PortalConfigFromBackend>) => {
      const configState = pick(state, [
        'minDepositUSDT',
        'tokenRegistry',
        'nonL2TokenRegistry',
        'exchangeSymbols',
        'newMarkets',
        'dlmMarkets',
        'settleSpread',
        'ammPools',
        'yieldMarkets',
        'bridgeConfigPerChain',
      ])

      // Avoid update from api if no changes
      if (isEqual(configState, action.payload)) {
        return state
      }

      return {
        ...state,
        minDepositUSDT: action.payload.minDepositUSDT,
        tokenRegistry: action.payload.tokenRegistry,
        nonL2TokenRegistry: action.payload.nonL2TokenRegistry,
        exchangeSymbols: action.payload.exchangeSymbols,
        newMarkets: action.payload.newMarkets,
        dlmMarkets: action.payload.dlmMarkets,
        settleSpread: action.payload.settleSpread,
        ammPools: action.payload.ammPools,
        yieldMarkets: action.payload.yieldMarkets,
        bridgeConfigPerChain: action.payload.bridgeConfigPerChain,
      }
    },
    setIngoreLocationRestriction: (state) => {
      return {
        ...state,
        ignoreLocationRestriction: true,
      }
    },
    setWithdrawals: (state, action: PayloadAction<{ withdrawals: WithdrawalState[] }>) => {
      return {
        ...state,
        withdrawals: action.payload.withdrawals,
      }
    },
    setDeposits: (state, action: PayloadAction<{ deposits: DepositState[] }>) => {
      return {
        ...state,
        deposits: action.payload.deposits,
      }
    },
    addPendingDeposit: (state, { payload: { pendingDeposit } }: PayloadAction<{ pendingDeposit: PendingDeposit }>) => {
      return {
        ...state,
        pendingDepositData: pendingDeposit ? [...state.pendingDepositData, pendingDeposit] : state.pendingDepositData,
      }
    },
    removePendingDeposit: (state, { payload: { depositId } }: PayloadAction<{ depositId: string }>) => {
      const pendingDepositFilteredOut = (pendingDepositLocal: PendingDeposit) => pendingDepositLocal.id !== depositId
      return {
        ...state,
        pendingDepositData: state.pendingDepositData.filter(pendingDepositFilteredOut),
      }
    },
    setNextBatchTime: (
      state,
      action: PayloadAction<{
        batchTime: {
          estimatedTime: string | number | null
          averageTime: string | number | null
          finalisedBatchPendingConfirmation?: boolean | undefined
          origAverageTime?: number | null | undefined
        }
      }>,
    ) => {
      return {
        ...state,
        batchTime: action.payload.batchTime,
      }
    },
    setRequiredChain: (
      state,
      {
        payload: { requiredChain },
      }: PayloadAction<{
        requiredChain: RequiredChain
      }>,
    ) => {
      return {
        ...state,
        requiredChain,
      }
    },
    setDefaultRoute: (state, action: PayloadAction<DefaultRouteType>) => {
      state.defaultRoute = action.payload
    },
  },
})
