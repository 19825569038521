import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit'
import { clearRTKCacheMiddleware } from '../middleware/clear-rtk-cache.middleware'
import { arbitrumChargeApi } from '../services/arbitrumChargeApi'
import { referralApi } from '../services/referralApi'
import { airdropsApi } from '../services/airdropsApi'
import { activityTrackersApi } from '../services/activityTrackers/activityTrackers.api'
import { tradingApi } from '../services/tradingApi'
import { boostApi } from '../services/yieldService/boostApi'
import { yieldApi } from '../services/yieldService/yieldApi'
import { listenerMiddleware } from '../middleware/listener.middleware'
import {
  l1RegistrationWalletConnectedMiddleware,
  l1RegistrationTradingKeySetMiddleware,
} from '../middleware/l1RegistrationPayload.middleware'
import { poolsApi } from '../services/poolService'
import { mintedNFTsApi } from '../services/mintedNFTsApi'
import { statsApi } from '../services/statsApi'
import { geoblockingApi } from '../services/geoblockingApi'
import { contractsInteractionsApi } from '../services/contractsInteractions/contractsInteractions.api'
import { marketDataApi } from '../services/marketDataApi'
import { settingsListenerMiddleware } from '../middleware/settings.middleware'
import { secondaryWalletMiddleware } from '../middleware/secondaryWallet.middleware'
import { nationApi } from '../pages/Nation/services/nation.api'
import { gatedFeaturesApi } from '../pages/Nation/services/gatedFeatures.api'
import { milestoneCampaignApi } from '../pages/MilestoneCampaign/services/milestoneCampaign.api'
import { rootReducer } from './rootReducer'
import type { FilledRootState, InferredRootState } from './rootReducer'
import type { AppDispatch as OriginalAppDispatch } from './store.types'

const config = {
  development: process.env.NODE_ENV !== 'production',
}

// reexport to not modify every place this is imported
export type RootState = FilledRootState
// const enhanceSentry = isFeatureEnabled(F_FLAGS_TYPES.DEV_ENCHANCE_SENTRY)
// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
//   stateTransformer: (state: FilledRootState) => {
//     if (!enhanceSentry) {
//       return null
//     }

//     return {
//       theme: state?.portal.selectedTheme,
//       application: state?.application,
//       address: state?.portal?.address,
//       secondaryWallet: state?.secondaryWallet,
//       authentication: state?.user?.authentication,
//       contractWallet: state?.user?.contractWallet?.isContractWallet,
//       wallet: {
//         walletType: state?.user?.wallet?.data?.walletType,
//         isConnecting: state?.user?.wallet?.isConnecting,
//         isRegistered: state?.user?.wallet?.isRegistered,
//       },
//     }
//   },
// })

export const configureStore = (preloadedState?: Partial<InferredRootState>) => {
  const { development } = config

  const store = rtkConfigureStore({
    preloadedState,
    reducer: rootReducer,
    devTools: development,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
        .prepend(listenerMiddleware.middleware)
        .concat(secondaryWalletMiddleware.middleware)
        .concat(settingsListenerMiddleware.middleware)
        .concat([l1RegistrationWalletConnectedMiddleware.middleware, l1RegistrationTradingKeySetMiddleware.middleware])
        .concat(poolsApi.middleware)
        .concat(statsApi.middleware)
        .concat(referralApi.middleware)
        .concat(airdropsApi.middleware)
        .concat(activityTrackersApi.middleware)
        .concat(contractsInteractionsApi.middleware)
        .concat(mintedNFTsApi.middleware)
        .concat(nationApi.middleware)
        .concat(gatedFeaturesApi.middleware)
        .concat(milestoneCampaignApi.middleware)
        .concat(tradingApi.middleware)
        .concat(marketDataApi.middleware)
        .concat(geoblockingApi.middleware)
        .concat(yieldApi.middleware)
        .concat(boostApi.middleware)
        .concat(arbitrumChargeApi.middleware)
        .concat(clearRTKCacheMiddleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({
        autoBatch: { type: 'timer', timeout: 1000 },
      }),
  })

  return store
}

// reexport to not modify every place this is imported
export type AppDispatch = OriginalAppDispatch
export type AppStore = ReturnType<typeof configureStore>
