import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  MilestoneCampaignConfigResponse,
  LeaderboardResponse,
  LeaderboardItemWithExpectedReward,
} from '@rhinofi/dvf-campaigns-api-spec'
import { envConfig } from '../../../env/envConfig'

export const milestoneCampaignApi = createApi({
  reducerPath: 'milestoneCampaignApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envConfig.tradingApi}/campaigns`,
  }),
  tagTypes: ['CampaignConfig', 'Leaderboard', 'Rank'],
  endpoints: (builder) => ({
    getMilestoneCampaignConfig: builder.query<MilestoneCampaignConfigResponse, string>({
      query: (campaignId) => ({ url: `/milestoneCampaignConfig/${campaignId}` }),
      providesTags: ['CampaignConfig'],
    }),
    getLeaderboardWithPagination: builder.query<
      LeaderboardResponse,
      { campaignId: string; page: number; itemsPerPage: number }
    >({
      query: ({ campaignId, page, itemsPerPage }) => {
        const skip = (page - 1) * itemsPerPage
        const limit = itemsPerPage
        return { url: `/milestoneLeaderboard/${campaignId}?skip=${skip}&limit=${limit}` }
      },
      providesTags: ['Leaderboard'],
    }),
    getLeaderboardRank: builder.query<LeaderboardItemWithExpectedReward, { campaignId: string; user: string }>({
      query: ({ campaignId, user }) => ({
        url: `/milestoneLeaderboardRank/${campaignId}/${user}`,
      }),
      providesTags: ['Rank'],
    }),
  }),
})

export const { useGetMilestoneCampaignConfigQuery, useGetLeaderboardWithPaginationQuery, useGetLeaderboardRankQuery } =
  milestoneCampaignApi
