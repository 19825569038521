import type { MissionId, MissionFrequency, GemsTransactionAPIDocument } from '@rhinofi/dvf-rhino-nation-api-spec'
import type { modalKeys } from '../../constants/modalKeys'
import type { AppDispatch } from '../../store/configureStore'
import type { BridgeHistoryItem } from '../../services/apiService/getBridgeHistory'
import type { YearWrappedData } from '../../services/yearWrapped/YearWrapped.types'
import type { GmSuccessType, UserContractCodec } from '../../services/contractsInteractions/contractsInteractions.types'
import type { TxStatus } from '../../services/txService/txService.types'
import { MODAL_SHOW } from '../modalActions'
import type { NFTType } from '../../services/nfts/mintNFT/mintNFT.types'

export type ModalShowAction = {
  type: 'MODAL_SHOW'
  payload:
    | {
        modalShown: typeof modalKeys.userVerificationEmail
        modalPayload: {
          onComplete: () => void
          initialStep?: number
          autoFocus?: boolean
        }
      }
    | {
        modalShown: typeof modalKeys.userVerificationPhone
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.claimRewards
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.yieldAlerts
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.newUserEmail
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.rhinoFiWrapped
        modalPayload: YearWrappedData
      }
    | {
        modalShown: typeof modalKeys.claimSuccess
        modalPayload: {
          claimedAmount: string
          claimedToken: string
        }
      }
    | {
        modalShown: typeof modalKeys.deposit
        modalPayload: {
          token?: string
        }
      }
    | {
        modalShown: typeof modalKeys.ledgerIsBusy
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.accruing
        modalPayload: {
          minAmount: string
        }
      }
    | {
        modalShown: typeof modalKeys.claiming
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.verify
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.withdrawal
        modalPayload: {
          initialToken?: string
          initialChain?: string
        }
      }
    | {
        modalShown: typeof modalKeys.recentBridgeTx
        modalPayload: Omit<BridgeHistoryItem, 'fee'>
      }
    | {
        modalShown: typeof modalKeys.nftMint
        modalPayload: {
          chain: string
          type: NFTType
          txHash?: string
          status: TxStatus
        }
      }
    | {
        modalShown: typeof modalKeys.viewNftMinted
        modalPayload: {
          chain: string
          type: NFTType
        }
      }
    | {
        modalShown: typeof modalKeys.freeBridge
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.reconnectModal
        modalPayload: {
          connectedChain: string
          chainToConnect: string
          resolve: (result: boolean) => boolean
        }
      }
    | {
        modalShown: typeof modalKeys.noWallet
        modalPayload: {
          chain: string
        }
      }
    | {
        modalShown: typeof modalKeys.airdropClaimCongrats
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.spendingCap
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.contractDetails
        modalPayload: { data: UserContractCodec }
      }
    | {
        modalShown: typeof modalKeys.contractShare
        modalPayload: { contractAddress: string; chain: string }
      }
    | {
        modalShown: typeof modalKeys.contractGM
        modalPayload: {
          txStatus: TxStatus
          txHash?: string
          chain: string
          successVariant: GmSuccessType
          retryFunc?: () => void
        }
      }
    | {
        modalShown: typeof modalKeys.contractDeploy
        modalPayload: {
          txStatus: TxStatus
          txHash?: string
          chain: string
          contractAddress?: string
          retryFunc?: () => void
        }
      }
    | {
        modalShown: typeof modalKeys.referralCodeVerification
        modalPayload: {
          isSuccess: boolean
        }
      }
    | {
        modalShown: typeof modalKeys.connectToParadex
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.nationOnboarding
        modalPayload: undefined
      }
    | {
        modalShown: typeof modalKeys.nationActiveMission
        modalPayload: {
          missionId: MissionId
          frequency: MissionFrequency
        }
      }
    | {
        modalShown: typeof modalKeys.nationCompletedMission
        modalPayload: {
          gemsTransaction: GemsTransactionAPIDocument
        }
      }
    | {
        modalShown: typeof modalKeys.nationActivePerk
        modalPayload: {
          perkId: string
        }
      }
    | {
        modalShown: typeof modalKeys.nationClaimedPerk
        modalPayload: {
          gemsTransaction: GemsTransactionAPIDocument
        }
      }
}

const makeShowModalAction = (payload: ModalShowAction['payload']): ModalShowAction => ({
  type: MODAL_SHOW,
  payload,
})

export const showModal =
  (dispatch: AppDispatch) =>
  <T extends ModalShowAction['payload']['modalShown']>(
    modalShown: T,
    modalPayload?: Extract<ModalShowAction['payload'], { modalShown: T }>['modalPayload'],
  ) => {
    // cast because TS for some reason doesn't want to recognize that this is valid.
    // showModal() accepts only matching shown and payload, f.e.:
    // valid:
    // showModal(dispatch)(xchainSuccessModalKey, { tokenFrom: 'test', tokenTo: 'test' })
    // invalid:
    // showModal(dispatch)(xchainSuccessModalKey, { bar: 'test', foo: 'test' })
    dispatch(makeShowModalAction({ modalShown, modalPayload } as ModalShowAction['payload']))
  }
